import { AnimatePresence, motion } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { Text, Title } from "../../../components";

const Section = styled.section`
  width: 100%;
  height: 100vh;
  background: #fdfdfd;
  position: relative;

  ${media.lessThan("medium")`
    padding-top: 60px;
    overflow: hidden;
  `}

  :after {
    content: "";
    width: 80%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: #27494a;
    z-index: 1;
    opacity: 0.1;

    ${media.lessThan("medium")`
      width: 100%;
    `}
  }
`;

const Container = styled.div`
  margin: 0 auto;
  width: 90%;
  max-width: calc(100% - 200px);
  position: relative;
  z-index: 2;

  ${media.lessThan("medium")`
    max-width: 90%;
  `}

  ${Title} {
    position: relative;

    ${media.lessThan("medium")`
      font-size: 30px;
    `}

    :after {
      content: "";
      width: 2px;
      height: 110px;
      background: #27494a;
      position: absolute;
      bottom: 100%;
      left: 40%;

      ${media.lessThan("medium")`
        left: 0;
      `}
    }
  }
`;

const Quotes = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: auto;
  margin-top: 130px;
  position: relative;
  background: white;

  ${media.lessThan("medium")`
    margin-top: 60px;
  `}
`;
const Quote = styled(motion.div)`
  width: 100%;
  background: white;
  padding: 160px;

  ${media.lessThan("medium")`
    padding: 30px;
  `}
`;

const Next = styled(motion.div)`
  width: 44px;
  height: 44px;
  opacity: ;
  background: ${({ disabled }) => (disabled ? "grey" : "#5273b0")};
  position: absolute;
  top: 0;
  bottom: 0;
  right: -22px;
  margin: auto;
  cursor: pointer;
  pointer-events: ${({ disabled }) => disabled && "none"};
  transition: opacity 400ms ease;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  padding-right: 5px;
`;

const Prev = styled(motion.div)`
  width: 44px;
  height: 44px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -22px;
  margin: auto;
  z-index: 2;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  background: ${({ disabled }) => (disabled ? "grey" : "#5273b0")};
  pointer-events: ${({ disabled }) => disabled && "none"};
  transition: opacity 400ms ease;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
`;

const Inner = styled.div`
  overflow: hidden;
`;

const Pattern = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 60%;
  margin: auto;
  z-index: -1;

  ${media.lessThan("medium")`
    top: 30%;
  `}
`;

const Wire = styled.div`
  position: absolute;
  top: -50%;
  left: -40%;
`;

const AboutUs = ({ data }) => {
  const [current, setCurrent] = useState(0);
  const [direction, setDirection] = useState(0);

  const quotes = data.edges.map(({ node }) => ({
    id: node.id,
    author: node.autor,
    text: node.comentario.childMarkdownRemark.html,
  }));

  const currentSlider = useMemo(() => {
    return quotes[current];
  }, [current, quotes]);

  return (
    <Section>
      <Wire>
        <StaticImage src="../../../images/svgs/logo-wire.svg" />
      </Wire>
      <Container>
        <Title as="h2" variant="h2" color="#27494A" style={{ maxWidth: 460 }}>
          O que falam sobre nós
        </Title>

        <Quotes>
          <Pattern>
            <StaticImage src="../../../images/svgs/logo-pattern.svg" />
          </Pattern>
          <Prev
            disabled={current === 0}
            whileHover={{ scale: 1.1 }}
            onTap={async () => {
              await setDirection(1);
              await setCurrent(current > 0 ? current - 1 : 0);
            }}
          >
            <StaticImage src="../../../images/svgs/arrow-left.svg" />
          </Prev>
          <Inner>
            <AnimatePresence exitBeforeEnter>
              <Quote
                key={`Quote_item-${currentSlider.id}`}
                initial={{ opacity: 0, x: direction === 0 ? 80 : -80 }}
                animate={{
                  opacity: 1,
                  x: 0,
                }}
                exit={{ opacity: 0, x: direction === 0 ? -80 : 80 }}
                transition={{ type: "spring", stiffness: 130, damping: 20 }}
              >
                <Text dangerouslySetInnerHTML={{ __html: currentSlider.text }} />
                <Text
                  style={{
                    marginTop: 30,
                    textTransform: "uppercase",
                    fontSize: 12,
                    fontWeight: 600,
                  }}
                >
                  {currentSlider.author}
                </Text>
              </Quote>
            </AnimatePresence>
          </Inner>
          <Next
            disabled={current === quotes.length - 1}
            whileHover={{ scale: 1.1 }}
            onTap={async () => {
              await setDirection(0);
              await setCurrent(current < quotes.length - 1 ? current + 1 : current);
            }}
          >
            <StaticImage src="../../../images/svgs/arrow-right.svg" />
          </Next>
        </Quotes>
      </Container>
    </Section>
  );
};

export default AboutUs;
