import styled from "styled-components";
import media from "styled-media-query";

import { motion } from "framer-motion";

import { Text } from "../../../components";

export const Section = styled.section`
  width: 100%;
  min-height: 105vh;
  display: flex;
  flex-flow: row wrap;
  position: relative;
  z-index: 1;
`;

export const ImageBackground = styled(motion.div)`
  width: calc(100% - 110px);
  display: flex;
  margin-left: auto;
  height: 105vh;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }

  ${media.lessThan("medium")`
    width: 100%;
    height: auto !important;
    min-height: 75vh;
    order: 1;

    .gatsby-image-wrapper [data-main-image] {
      height: 100% !important;
    }
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-flow: column wrap;
  background: white;
  padding: 0 36px;
  width: clamp(600px, 50vw, 680px);
  position: absolute;
  bottom: 0;
  z-index: 3;
  align-items: flex-start;
  justify-content: center;
  margin-left: 110px;
  height: clamp(600px, 90vh, 440px);

  ${media.lessThan("medium")`
    margin-left: 0;
    width: 100%;
    height: auto;
    padding: 20px;
    padding-bottom: 80px;
    position: relative;
    order: 2;
    justify-content: flex-start;
    padding-top: 120px;

    h1 {
      font-size: 50px;
    }
  `}
`;

export const Logo = styled.div`
  width: clamp(640px, 60vw, 540px);
  height: clamp(640px, 60vw, 540px);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  transform: translate3d(56.5%, 0px, 0);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  

  ${media.lessThan("medium")`
    height: 200px;
    width: 200px;
    right: unset;
    transform: translate(0, 0);
    top: -100px;
  `}
`;

export const InnerContent = styled.div`
  width: 100%;
  position: relative;
  z-index: 2;
  overflow: hidden;
`;

export const Description = styled(Text)`
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  :before {
    content: "";
    width: 40px;
    height: 1px;
    background: ${({ theme }) => theme.colors.brandLight};
    margin-right: 10px;
  }
`;

export const BulletsHolder = styled.div`
  position: absolute;
  bottom: 64px;
  left: 36px;

  ${media.lessThan("medium")`
    bottom: 60px;
  `}
`;