import { AnimatePresence, motion } from "framer-motion";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import React, { useMemo, useState } from "react";

import { navigate } from "gatsby";
import { Bullets, Button, Title } from "../../../components";
import {
  BulletsHolder,
  Content,
  Description,
  ImageBackground,
  InnerContent,
  Logo,
  Section,
} from "./style";

const Hero = ({ data }) => {
  const slides = data.edges.map(({ node }) => ({
    id: node.id,
    title: node.titulo,
    text: node.texto.childMarkdownRemark.html,
    link: "/",
    image: node.imagem,
  }));

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const currentSlide = useMemo(() => {
    return slides[currentSlideIndex];
  }, [currentSlideIndex, slides]);

  return (
    <Section>
      <Content>
        <Logo>
          <StaticImage src="../../../images/svgs/logo.svg" />
        </Logo>
        <InnerContent>
          <AnimatePresence exitBeforeEnter>
            <Title
              style={{ maxWidth: "80%" }}
              as={motion.h1}
              color="brandGreen"
              key={`${currentSlideIndex}_title`}
              initial={{ position: "relative", left: 20, opacity: 0 }}
              animate={{
                position: "relative",
                left: 0,
                opacity: 1,
              }}
              exit={{ position: "relative", left: -20, opacity: 0 }}
              transition={{ type: "spring", stiffness: 300, damping: 70 }}
            >
              {currentSlide.title}
            </Title>
            <Description
              as={motion.p}
              style={{ marginBottom: "clamp(30px, 5vw, 80px)" }}
              color="lightBlue"
              key={`${currentSlideIndex}_text`}
              initial={{ position: "relative", left: 20, opacity: 0 }}
              animate={{
                position: "relative",
                left: 0,
                opacity: 1,
                transition: { type: "spring", bounce: 0.1, delay: 0.1 },
              }}
              exit={{ position: "relative", left: -20, opacity: 0, transition: { delay: 0.1 } }}
              dangerouslySetInnerHTML={{ __html: currentSlide.text }}
            />

            <Button
              key={`${currentSlideIndex}_button`}
              initial={{ left: 20, opacity: 0 }}
              animate={{ left: 0, opacity: 1, transition: { type: "spring", bounce: 0.1 } }}
              exit={{ left: -20, opacity: 0, transition: { delay: 0.1 } }}
              onClick={() => navigate('/a-origgem')}
            >
              Saiba mais
            </Button>
          </AnimatePresence>
        </InnerContent>

        {slides.length > 1 && (
          <BulletsHolder>
            <Bullets
              total={slides.length}
              active={currentSlideIndex}
              onChangeCurrent={(current) => setCurrentSlideIndex(current)}
            />
          </BulletsHolder>
        )}
      </Content>
      <AnimatePresence exitBeforeEnter>
        <ImageBackground style={{ overflow: "hidden" }}>
          <ImageBackground
            key={`ImageBlock_${currentSlide.image}`}
            initial={{ opacity: 0, scale: 0.99 }}
            animate={{ opacity: 1, scale: 1, transition: { type: "spring", bounce: 0 } }}
            exit={{ opacity: 0, scale: 1.01 }}
          >
            <GatsbyImage
              image={getImage(currentSlide.image.gatsbyImageData)}
              alt={currentSlide.image.description || "Descricao nova"}
              title={currentSlide.image.title}
              loading="eager"
            />
          </ImageBackground>
        </ImageBackground>
      </AnimatePresence>
    </Section>
  );
};

export default Hero;